import React from "react"
import { graphql } from "gatsby"
import BlogPage from "./blog"

type Post = {
  id: string
  frontmatter: {
    path?: string
    title: string
    subtitle: string
    postcategory: string
    headerImage?: {
      image: {
        relativePath: string
      }
    }
    date: string
  }
  fields: {
    slug: string
  }
}

type BitcoinfiPageProps = {
  data: {
    allMarkdownRemark: {
      nodes: Post[]
    }
  }
}

function BitcoinfiPage({ data }: BitcoinfiPageProps) {
  const filteredPosts = data.allMarkdownRemark.nodes.filter(
    (post) => post.frontmatter.postcategory === "bitcoinfi",
  )

  return <BlogPage data={{ allMarkdownRemark: { nodes: filteredPosts } }} />
}

export const query = graphql`
  query postListQuery {
    allMarkdownRemark(
      filter: { frontmatter: { template: { eq: "blog-post" } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        id
        fields {
          slug
        }
        frontmatter {
          path
          title
          subtitle
          richTitle {
            html
          }
          postcategory
          date(formatString: "MMMM DD, YYYY")
          byline
          headerImage {
            image {
              relativePath
            }
            alt
          }
        }
      }
    }
  }
`

export default BitcoinfiPage